import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { isEqual, uniqWith } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Checkbox,
    Col,
    Form,
    FormInstance,
    Input,
    Popconfirm,
    message,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import { PickupAddress } from 'components/pickup/create-pickup/pickup.types';
import {
    BookOutlined,
    DeleteOutlined,
    EditOutlined,
} from '@ant-design/icons';
import Helper from 'library/Helper';
import Loader from 'components/common/Loader';
import CreateAddressForm from 'components/address/create-address';
import {
    deleteAddress,
    fetchPickupAddress,
    getDefaultAddress,
    setDefaultAddressData,
    editAddress as editBusinessAddress,
} from '../../../../network/pickup.api';
import { useDebounce } from 'hooks/use-debounce';
import { formRules } from 'library/constants';

const { useState } = React;

const styles = (theme: ThemeType) => ({
    addressMenu: {
        ...commonStyleSheet(theme).flexColumn,
        marginTop: 16,
        minWidth: 188,
        boxSizing: 'border-box',
        width: 1,
        borderRight: '1px solid #EDEDED',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        main: {
            '& .ant-radio-wrapper': {
                backgroundColor: 'blue',
            },
        },
    },
    addressItem: {
        ...commonStyleSheet(theme).flexRow,
        height: 42,
        width: 188,
        alignItems: 'center',
        cursor: 'pointer',
    },
    addressItemSelected: {
        ...commonStyleSheet(theme).flexRow,
        height: 42,
        width: 188,
        alignItems: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
        borderRight: `4px solid ${theme.secondryColor}`,
    },
    box: {
        ...commonStyleSheet(theme).flexRow,
        padding: 20,
        alignItems: 'normal',
        boxSizing: 'border-box',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
    },
    tabIcon: {
        height: 24,
        width: 24,
        borderRadius: 12,
        margin: '0px 10px',
    },
    filledCircle: {
        height: 20,
        width: 20,
        borderRadius: 10,
        border: '2px solid #FFF',
    },
    addressDetails: {
        ...commonStyleSheet(theme).flexRow,
        alignItems: 'top',
        width: '100%',
        marginTop: 16,
    },
    addressBox: {
        width: '100%',
        margin: '0 20px',
    },
    addressLineDetails: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        alignItems: 'top',
        width: '100%',
        padding: '0px 10px 0px 25px',
    },
    addressList: {
        ...commonStyleSheet(theme).flexColumn,
        overflow: 'auto',
    },
    addressLine: {
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
        width: '98%',
        borderRadius: 4,
        backgroundColor: '#FCFCFC',
        margin: '4px 0px',
        cursor: 'pointer',
    },
    addressLineSelected: {
        backgroundColor: '#E7F5FF',
    },
    checkbox: {
        display: 'flex',
        color: '#111111',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
        padding: 12,
        borderRadius: 4,
    },
    addressName: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    searchAddress: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'flex-end',
        margin: 0,
    },
    line: {
        height: 1,
        margin: '12px 0px',
        width: '100%',
        backgroundColor: '#EDEDED',
    },
    formItem: {
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    field: any;
}

const SingleAddressField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        uiTheme,
        form,
        showAddressCode,
        defaultMultipleLimit,
        allowDefaultSourceAddress,
        phoneRegex,
        countryWisePhoneRegex,
        field,
    } = props;
    const { ConsignmentCategory } = formFields;
    const [defaultAddress, setDefaultAddress] = useState<Record<any, any>>({});

    const currentAddressType = field.key || 'srcAddress';
    const [addressList, setAddressList] = useState<PickupAddress[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const selectedAddress: any = Form.useWatch(currentAddressType, form);
    const [addressType, setAddressType] = React.useState<string>('saved');
    const [searchString, setSearchString] = useState<string>('');
    const [editAddress, setEditAddress] = React.useState<PickupAddress | undefined>();
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [billToSame, setBillToSame] = useState<boolean>(false);
    const [isDefaultLoaded, setIsDefaultLoaded] = useState(false);
    const dstAddressForBillDetails = Form.useWatch('dstAddress', form);

    const loadDefaultAddress = async () => {
        if (!allowDefaultSourceAddress) return;
        setLoading(true);
        const response = await getDefaultAddress();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setDefaultAddress(response.data);
        }
        setLoading(false);
    };

    const loadAddress = async (key: string) => {
        if (addressType !== 'saved') {
            return;
        }
        const formData = form.getFieldsValue();
        let defaultAdd : Record<any, any> = {};
        if (allowDefaultSourceAddress && !isDefaultLoaded && key === 'srcAddress') {
            const response = await getDefaultAddress();
            if (response.isSuccess) {
                defaultAdd = response?.data || {};
                setDefaultAddress(defaultAdd);
                setIsDefaultLoaded(true);
            }
        } else {
            defaultAdd = defaultAddress;
        }
        let showDefaultBy = '';
        if (defaultMultipleLimit) {
            if (key === 'srcAddress') {
                showDefaultBy = 'src';
            } else {
                showDefaultBy = 'dst';
            }
        }
        setLoading(true);
        const response = await fetchPickupAddress({
            showDefaultBy,
            searchString: searchString || '',
        });
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        }
        let data = response?.data || [];
        // eslint-disable-next-line no-underscore-dangle
        if (key === 'srcAddress' && allowDefaultSourceAddress && defaultAdd?._id
            && searchString.length < 1 && !defaultMultipleLimit) {
            let add: PickupAddress | undefined;
            data = await data.filter((item: PickupAddress) => {
                // eslint-disable-next-line no-underscore-dangle
                if (item.id === defaultAdd._id) {
                    add = item;
                    return false;
                }
                return true;
            });
            if (add) await data.unshift(add);
        }
        if (formData?.previousSelectedAddress && formData?.previousSelectedAddress[key]) {
            data.unshift(formData?.previousSelectedAddress[key]);
        }
        setAddressList(uniqWith(data, isEqual));
        setLoading(false);
    };

    const debounceTimeSrc = useDebounce(searchString);
    React.useEffect(() => {
        loadAddress(currentAddressType);
    }, []);

    React.useEffect(() => {
        loadAddress(currentAddressType);
    }, [debounceTimeSrc, addressType, toggle]);

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);

    const prettyPrintedNumber = (num: string) => {
        return num;
    };

    const renderAddressDetails = (item: PickupAddress) => {
        const isRTL = uiTheme?.isRTL;
        const customerCode = window.localStorage.getItem('userCode');
        return (
            <div className={classes.addressLineDetails}>
                <div style={{ minWidth: '30%' }}>
                    {item.addressLine1 || ''}
                    {item.addressLine1 ? ', ' : ''}
                    {item.addressLine2 || ''}
                </div>
                <div style={{
                    minWidth: '30%',
                    marginLeft: isRTL ? undefined : '10%',
                    marginRight: isRTL ? '10%' : undefined,
                }}
                >
                    {item.pincode || ''}
                    {item.pincode && item.cityName ? ' - ' : ''}
                    {item.cityName || ''}
                    <br />
                    {item.stateName || ''}
                    {item.stateName ? ', ' : ''}
                    {item.countryName || ''}
                </div>
                <div style={{ minWidth: '30%', textAlign: 'right' }}>
                    {item.phone
                        ? (
                            <>
                                {prettyPrintedNumber(item.phone)}
                                <br />
                            </>
                        ) : null}
                    {item.alternatePhone
                        ? (
                            <>
                                {prettyPrintedNumber(item.alternatePhone)}
                                <br />
                            </>
                        ) : null}
                    {item.email ? item.email : null}
                    <b>{`${item.locationId || ''}`}</b>
                    <br />
                    <b>{item.locationId ? item.locationCode || `${customerCode}_${item.locationId}` : ''}</b>
                </div>
            </div>
        );
    };

    const isCurrentAddressSelected = (item: PickupAddress) => {
        return Boolean(selectedAddress && item.id === selectedAddress?.id);
    };

    const renderEdit = (item: PickupAddress) => {
        const isAddressSelected = isCurrentAddressSelected(item);
        return (
            <EditOutlined
                onClick={(e) => {
                    Helper.stops(e as any);
                    if (isAddressSelected) {
                        return;
                    }
                    setAddressType('createnew');
                    setEditAddress(item);
                }}
                style={{ color: isAddressSelected ? '#cccccc' : uiTheme.primaryColor, marginRight: '8px' }}
            />
        );
    };

    const saveDefaultAddress = async (address: PickupAddress, isMultipleDefault: boolean = false) => {
        setLoading(true);
        if (isMultipleDefault) {
            const response = await editBusinessAddress(address);
            if (!response.isSuccess) {
                message.error(response.errorMessage);
            } else {
                loadAddress(currentAddressType);
            }
        } else {
            const id = address.id;
            const response = await setDefaultAddressData({
                businessAddressId: id,
            });
            if (!response.isSuccess) {
                message.error(response.errorMessage);
            } else {
                loadDefaultAddress();
                setLoading(true);
                const key = 'srcAddress';
                if (currentAddressType === key) {
                    let data = addressList || [];
                    // eslint-disable-next-line no-underscore-dangle
                    if (allowDefaultSourceAddress && id && searchString.length < 1) {
                        let add: PickupAddress | undefined;
                        data = data.filter((item: PickupAddress) => {
                            // eslint-disable-next-line no-underscore-dangle
                            if (item.id === id) {
                                add = item;
                                return false;
                            }
                            return true;
                        });
                        if (add) data.unshift(add);
                        setAddressList(data);
                    }
                }
            }
        }
        setLoading(false);
    };

    const renderMarkAsDefaultMultiple = (item: PickupAddress) => {
        const isMultipleDefault = currentAddressType === 'srcAddress'
            ? item?.isDefaultSrc : item?.isDefaultDst;
        return (
            <>
                { isMultipleDefault ? (
                    <Button
                        style={{ cursor: 'default' }}
                        shape="round"
                    >
                        Default
                    </Button>
                ) : null}
                { isMultipleDefault ? (
                    <Popconfirm
                        placement="topLeft"
                        title="Are you sure you want to remove this default Address"
                        onConfirm={(e) => {
                            Helper.stops(e as any);
                            saveDefaultAddress({
                                ...item,
                                isDefaultSrc: currentAddressType === 'srcAddress' ? false : item?.isDefaultSrc,
                                isDefaultDst: currentAddressType !== 'srcAddress' ? false : item?.isDefaultDst,
                            }, true);
                        }}
                        onCancel={(e) => Helper.stops(e as any)}
                    >
                        <BookOutlined
                            style={{ color: 'red' }}
                            onClick={(e) => { Helper.stops(e as any); }}
                        />
                    </Popconfirm>
                ) : (
                    <BookOutlined
                        onClick={(e) => {
                            Helper.stops(e as any);
                            saveDefaultAddress({
                                ...item,
                                isDefaultSrc: currentAddressType === 'srcAddress' ? true : item?.isDefaultSrc,
                                isDefaultDst: currentAddressType !== 'srcAddress' ? true : item?.isDefaultDst,
                            }, true);
                        }}
                        style={{ color: uiTheme.primaryColor, marginRight: 8 }}
                    />
                )}
            </>
        );
    };

    const renderMarkAsDefault = (item: PickupAddress) => {
        if (defaultMultipleLimit) {
            return renderMarkAsDefaultMultiple(item);
        }
        // eslint-disable-next-line no-underscore-dangle
        if (defaultAddress._id === item.id) {
            return (
                <Button
                    style={{ cursor: 'default' }}
                    shape="round"
                >
                    Default
                </Button>
            );
        }
        return (
            <BookOutlined
                onClick={(e) => {
                    Helper.stops(e as any);
                    saveDefaultAddress(item);
                }}
                style={{ color: uiTheme.primaryColor, marginRight: 8 }}
            />
        );
    };
    const showaddcode = (item: PickupAddress) => {
        return (
            <span style={{ display: 'flex', fontWeight: 'bold' }}>
                <span>{item?.addressCode}</span>
                <span style={{ margin: '0px 6px 0px 4px', fontWeight: 'bold' }}> | </span>
            </span>
        );
    };

    const handleDelete = async (e: any, id: string) => {
        Helper.stops(e);
        const result = await deleteAddress({ id });
        if (result.isSuccess) {
            message.success('Address Deleted Successfully');
            setToggle(!toggle);
        } else {
            message.error(result.errorMessage);
        }
    };

    const renderDelete = (item: PickupAddress) => {
        return (
            <Popconfirm
                placement="topLeft"
                title={t('delete_address_text')}
                onConfirm={(e) => handleDelete(e, item.id)}
                okText={t('ok_text')}
                cancelText={t('cancel')}
                onCancel={(e) => Helper.stops(e as any)}
            >
                <DeleteOutlined
                    onClick={(e) => {
                        Helper.stops(e as any);
                    }}
                    style={{ color: 'red' }}
                />
            </Popconfirm>
        );
    };

    const handleAddressChange = (e: any, key: string, item: PickupAddress) => {
        const isCurrentSelected = isCurrentAddressSelected(item);
        if (currentAddressType === 'billToDetails') {
            setBillToSame(false);
        }
        Helper.stops(e as any);
        // remove selection if clicked on same row
        if (isCurrentSelected) {
            form.setFieldsValue({
                [key]: null,
            });
        } else {
            // save address
            form.setFieldsValue({
                [key]: item,
            });
        }
    };

    const renderCheckbox = (item: PickupAddress, key: string) => {
        const showEditButtons = !['oldDstAddress', 'oldSrcAddress'].includes(item?.id);
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
            }}
            >
                <Checkbox
                    name={`${key}-checkbox`}
                    key={item.id}
                    checked={isCurrentAddressSelected(item)}
                    className={classes.checkbox}
                    onChange={(e) => handleAddressChange(e, key, item)}
                >
                    <div className={classes.addressName}>
                        <span style={{ display: 'flex', flex: 1 }}>
                            {showAddressCode && item?.addressCode ? showaddcode(item) : null}
                            <span style={{ display: 'inline-block', fontWeight: 'bold' }}>
                                {item.name || ''}
                            </span>
                        </span>
                    </div>
                </Checkbox>
                {
                    showEditButtons && (
                        <div style={{ justifyContent: 'flex-end' }}>
                            {allowDefaultSourceAddress ? renderMarkAsDefault(item) : null}
                            {renderEdit(item)}
                            {renderDelete(item)}
                        </div>
                    )
                }
            </div>
        );
    };

    const renderAddressLine = (item: PickupAddress, key: string) => {
        let className = classes.addressLine;
        const isCurrentSelected = isCurrentAddressSelected(item);
        if (isCurrentSelected) {
            className = [classes.addressLineSelected, classes.addressLine].join(' ');
        }
        return (
            <div
                key={item.id}
                onClick={(e) => handleAddressChange(e, key, item)}
                className={className}
            >
                {renderCheckbox(item, key)}
                {renderAddressDetails(item)}
            </div>
        );
    };

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const renderAddressList = (key: string) => {
        if (loading) {
            return <Loader zIndex={10} />;
        }
        return (
            <Form.Item
                name={key}
                className={classes.formItem}
                rules={['srcAddress', 'dstAddress'].indexOf(key) > -1 ? [formRuleRequired] : []}
            >
                <div className={classes.addressList}>
                    {addressList?.map((item: PickupAddress) => renderAddressLine(item, key))}
                </div>
            </Form.Item>
        );
    };

    const renderCreateAddressButton = () => {
        return (
            <>
                <Button
                    type="link"
                    onClick={() => {
                        setAddressType('createnew');
                        setEditAddress(undefined);
                    }}
                >
                    +
                    {t('add_new_address')}
                </Button>
            </>
        );
    };

    const renderSelectDefaultAddress = (key: string) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!defaultAddress?._id) return null;
        if (defaultMultipleLimit) return null;
        return (
            <Button
                type="link"
                onClick={() => {
                    // eslint-disable-next-line no-underscore-dangle
                    const item: PickupAddress | undefined = addressList?.find(
                        // eslint-disable-next-line no-underscore-dangle
                        (add) => add.id === defaultAddress?._id,
                    );
                    if (item) {
                        form.setFieldsValue({
                            [key]: item,
                        });
                    }
                }}
            >
                {t('select_default_address')}
            </Button>
        );
    };

    const renderAddressSearch = (key: string) => {
        return (
            <div className={classes.searchAddress}>
                { currentAddressType === 'billToDetails'
                    ? (
                        <Checkbox
                            checked={billToSame}
                            onChange={(e) => {
                                setBillToSame(e.target.checked);
                                if (e.target.checked) {
                                    form.setFieldsValue({
                                        billToDetails: dstAddressForBillDetails,
                                    });
                                }
                            }}
                            style={{
                                marginLeft: 8,
                                marginRight: 'auto',
                            }}
                        >
                            {t('use_same_as_delivery_address')}
                        </Checkbox>
                    )
                    : null}
                {renderCreateAddressButton()}
                {allowDefaultSourceAddress ? renderSelectDefaultAddress(key) : null}
                <Input
                    placeholder={t('search_for_addresses')}
                    type="search"
                    onChange={(e) => setSearchString(e.target.value)}
                    style={{ width: 200 }}
                />
            </div>
        );
    };

    const renderLine = () => {
        return (
            <div className={classes.line} />
        );
    };

    const renderExisting = (key: string) => {
        return (
            <>
                {renderAddressSearch(key)}
                {renderLine()}
                {renderAddressList(key)}
                {renderLine()}
            </>
        );
    };

    const renderAddressForm = (key: string) => {
        const createNew = addressType === 'createnew';
        const newAddressComp = createNew ? (
            <div>
                <Button onClick={() => {
                    setAddressType('saved');
                    setEditAddress(undefined);
                }}
                >
                    {t('Back')}
                </Button>
                <CreateAddressForm
                    editData={editAddress}
                    page="Address"
                    onClose={() => {
                        setAddressType('saved');
                        setEditAddress(undefined);
                    }}
                    phoneRegex={phoneRegex}
                    isInternationalAddress={
                        consignmentCategory === 'international' && currentAddressType !== 'srcAddress'
                    // || (editAddress?.isInternational || false)
                    }
                    countryWisePhoneRegex={countryWisePhoneRegex}
                />
            </div>
        ) : <></>;
        const addressForm = (
            <div style={{ display: createNew ? 'none' : 'block' }}>
                {renderExisting(key)}
            </div>
        );
        return (
            <>
                {newAddressComp}
                {addressForm}
            </>
        );
    };

    return (
        <Col span={24}>
            <div className={classes.box}>
                <div className={classes.addressMenu}>
                    <div
                        key={field.key}
                        className={classes.addressItemSelected}
                    >
                        {t(field.label) || field.label}
                    </div>
                </div>
                <div className={classes.addressDetails}>
                    <div
                        className={classes.addressBox}
                        style={{
                            display: 'block',
                            height: '500px',
                            overflowY: 'scroll',
                        }}
                    >
                        {renderAddressForm(currentAddressType)}
                    </div>
                </div>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master, uiTheme } = state;
    const { config, parts_to_show } = master;
    const customerPortalConfig = config?.customer_portal_config;
    const showAddressCode = config?.customer_portal_config?.allow_address_code_in_addresses;
    const disableDefaultAddress = !customerPortalConfig?.disable_default_address;
    const defaultAddressLimit = customerPortalConfig?.default_addresses_limit;
    const allowDefaultSourceAddress = disableDefaultAddress && defaultAddressLimit;
    const defaultMultipleLimit = config?.customer_portal_config?.default_addresses_limit;
    const phoneRegex = parts_to_show?.remove_regex ? null : config?.phone_regex;
    const countryWisePhoneRegex = config?.country_wise_phone_regex || [];
    return {
        uiTheme,
        showAddressCode,
        allowDefaultSourceAddress,
        defaultMultipleLimit,
        phoneRegex,
        countryWisePhoneRegex,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(SingleAddressField);
