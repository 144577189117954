import Helper from 'library/Helper';
import moment from 'moment';

const convertItemDetailToItems = (items_detail: any) => {
    if (!items_detail) return undefined;
    const items: Record<any, any> = {};
    items_detail.forEach((item: any, index: number) => {
        items[`itemOld${index}`] = {
            hsnCode: item.hsn_code,
            gstValue: item.gst_value,
            ateNumber: item.ate_number,
            cessValue: item.cess_value,
            dutyValue: item.duty_value,
            itemValue: item.item_value,
            itemRate: item.item_rate,
            numberOfItems: item.no_of_items,
            skuNumber: item.sku_number,
            composition: item.composition,
            noOfItems: item.no_of_items,
            productUrl: item.product_url,
            pieceNumber: item.piece_number,
            productType: item.product_type,
            productWidth: item.product_width,
            rodtepScheme: item.rodtep_scheme,
            gstPercentage: item.gst_percentage,
            productHeight: item.product_height,
            productLength: item.product_length,
            originOfGoods: item.origin_of_goods,
            itemDescription: item.item_description,
            productDeadWeight: item.product_weight,
            itemSize: item.item_size,
            key: `itemOld${index}`,
        };
    });
    return items;
};
const convertPiecesDetailToPieces = (pieces_detail: any) => {
    const pieces: Record<any, any> = {};
    pieces_detail?.forEach((piece: any, index: number) => {
        pieces[`pieceOld${index}`] = {
            dimensionsUnit: 'cm',
            weightUnit: 'kg',
            declaredValue: piece.declared_value,
            ...piece,
            unit: piece.item_unit,
            numberOfPieces: piece.quantity || piece.item_quantity || piece.number_of_items,
            key: `pieceOld${index}`,
            items: convertItemDetailToItems(piece.item_details),
        };
    });
    return pieces;
};

const covertDetailsToCustomerPortal = (fullData: any) => {
    const consignment = fullData?.source === 'plugin' ? fullData?.softdata_body : fullData;
    if (!consignment) return {};
    const isCod = consignment?.is_cod ? 'yes' : 'no';
    return {
        isEditingCN: fullData?.source !== 'plugin',
        childClient: fullData?.source !== 'plugin' ? `${consignment.customer_code}+${consignment.customer_id}` : null,
        referenceNumber: consignment.reference_number,
        customerReferenceNumber: fullData?.customer_reference_number,
        shopifyFulfillmentId: consignment?.shopify_fulfillment_id,
        shopifyOrderId: consignment?.shopify_order_id,
        akNumber: consignment.ak_number,
        status: consignment.status,
        isCOD: fullData?.source !== 'plugin' ? consignment.is_cod : isCod,
        codAmount: consignment.cod_amount,
        codCollectionMode: consignment.cod_collection_mode,
        codFavorOf: consignment.cod_favor_of,
        consignmentCategory: consignment?.is_international ? 'international' : 'domestic',
        pieces: convertPiecesDetailToPieces(fullData.source === 'plugin' ? consignment?.pieces : consignment.pieces_detail),
        isInternational: consignment.is_international,
        declaredValue: consignment.declared_value,
        declaredCurrency: consignment.declared_currency,
        serviceType: consignment.service_type,
        previousSelectedAddress: {
            dstAddress: {
                id: 'oldDstAddress',
                name: consignment.destination_name,
                phone: consignment.destination_phone,
                alternatePhone: consignment.alternate_phone,
                addressLine1: consignment.destination_address_line_1,
                addressLine2: consignment.destination_address_line_2,
                addressLine3: consignment.destination_address_line_3,
                addressLine4: consignment.destination_address_line_4,
                stateName: consignment.destination_state,
                cityName: consignment.destination_city,
                pincode: consignment.destination_pincode,
                countryName: consignment.destination_country,
                latitude: '',
                longitude: '',
            },
            srcAddress: {
                id: 'oldSrcAddress',
                name: consignment.sender_name,
                phone: consignment.sender_phone,
                alternatePhone: consignment.sender_alternate_phone,
                addressLine1: consignment.sender_address_line_1,
                addressLine2: consignment.sender_address_line_2,
                addressLine3: consignment.sender_address_line_3,
                addressLine4: consignment.sender_address_line_4,
                stateName: consignment.sender_state,
                cityName: consignment.sender_city,
                pincode: consignment.sender_pincode,
                countryName: consignment.sender_country,
                latitude: '',
                longitude: '',
            },
        },
        dstAddress: {
            id: 'oldDstAddress',
            name: consignment.destination_name,
            phone: consignment.destination_phone,
            alternatePhone: consignment.alternate_phone,
            addressLine1: consignment.destination_address_line_1,
            addressLine2: consignment.destination_address_line_2,
            addressLine3: consignment.destination_address_line_3,
            addressLine4: consignment.destination_address_line_4,
            stateName: consignment.destination_state,
            cityName: consignment.destination_city,
            pincode: consignment.destination_pincode,
            countryName: consignment.destination_country,
            latitude: '',
            longitude: '',
        },
        srcAddress: {
            id: 'oldSrcAddress',
            name: consignment.sender_name,
            phone: consignment.sender_phone,
            alternatePhone: consignment.sender_alternate_phone,
            addressLine1: consignment.sender_address_line_1,
            addressLine2: consignment.sender_address_line_2,
            addressLine3: consignment.sender_address_line_3,
            addressLine4: consignment.sender_address_line_4,
            stateName: consignment.sender_state,
            cityName: consignment.sender_city,
            pincode: consignment.sender_pincode,
            countryName: consignment.sender_country,
            latitude: '',
            longitude: '',
        },
        isRiskSurchargeApplicable: consignment?.is_risk_surcharge_applicable,
        description: consignment?.description,
        consignmentType: consignment?.consignment_type || 'forward',
        courierType: consignment?.load_type?.toLowerCase(),
        retAddress: {
            ...consignment?.ret_address,
        },
        alternateDelAddress1: {
            ...consignment?.alternate_del_address1,
        },
        alternateDelAddress2: {
            ...consignment?.alternate_del_address2,
        },
        redirectionAddress: {
            ...consignment?.redirection_address,
        },
        commodityId: Helper.isValidArray(consignment?.commodity_id)
            ? [consignment.commodity_name, consignment?.commodity_id[0]]
            : [consignment.commodity_name, consignment?.commodity_id],
        currency: consignment?.declared_currency,
        copAmount: consignment?.cashonpickup,
        isRetailTransaction: consignment?.is_retail_transaction,
        typeOfDelivery: consignment?.type_of_delivery,
        length: consignment?.length,
        width: consignment?.width,
        height: consignment?.height,
        weight: consignment?.weight,
        kiranaHubCode: consignment?.kirana_hub_code,
        numberOfPieces: Number(consignment?.num_pieces || consignment?.no_of_pieces),
        custSellerCode: consignment?.cust_seller_code,
        shipmentPurpose: consignment?.shipment_purpose,
        shipmentPurposeDoc: consignment?.shipment_purpose,

        deliveryInstructions: consignment.delivery_instructions,
        eoriNumber: consignment.eori_number,
        ioriNumber: consignment.iori_number,
        freightCost: consignment.freight_cost,
        freightCostCurrency: consignment.freight_cost_currency,
        againstBondLut: consignment.against_bond_lut,
        ecomShipment: consignment?.ecom_shipment?.toString(),
        meis: consignment?.meis?.toString(),
        isBattery: consignment?.is_battery?.toString(),
        invoiceDate: (consignment?.invoice_date || consignment?.export_invoice_date)
            ? moment((consignment?.export_invoice_date || consignment?.invoice_date), 'YYYY-MM-DD') : undefined,
        fobValueCurrency: consignment.fob_value_currency,
        fobValue: consignment.fob_value,
        totalGSTPaidCurrency: consignment.total_gst_paid_currency,
        totalGSTPaidAmount: consignment.total_GST_paid_amount,
        consignorCompanyName: consignment.consignor_company_name,
        consignorTaxId: consignment.consignor_tax_id,
        consignorIECNumber: consignment.consignor_IEC_number,
        consignorGSTINNumber: consignment.consignor_GST_IN_number,
        consignorPanId: consignment.consignor_pan_id,
        consignorKycDocType: consignment.consignor_kyc_doc_type,
        consignorKycDocNumber: consignment.consignor_kyc_doc_number,
        consignorKycFrontImage: consignment.consignor_kyc_front_image,
        consignorKycBackImage: consignment.consignor_kyc_back_image,
        senderBankAccountNumber: consignment.sender_bank_account_number,
        senderBankADCode: consignment.sender_bank_AD_code,
        senderBankIFSCCode: consignment.sender_bank_IFSC_code,
        nefiFlag: consignment?.nefi_flag?.toString(),
        cpcCode: consignment.cpc_code,
        cessValue: consignment.cess_value,
        ewayBillNumber: consignment.eway_bill_number,
        invoiceType: consignment.invoice_type,
        invoiceNumber: consignment.invoice_number,
        consigneeCompanyName: consignment.consignee_company_name,
        deliveryTerms: consignment.delivery_terms,
        originType: consignment.origin_type,
        destinationType: consignment.destination_type,
        dimensionUnit: consignment.dimension_unit,
        weightUnit: consignment.weight_unit,
        receiverVATNumber: consignment.receiver_VAT_number,
        pickupDate: fullData?.source === 'plugin' && consignment.pickup_date
            ? moment(consignment.pickup_date, 'YYYY-MM-DD') : undefined,
        pickupTime: fullData?.source === 'plugin' && consignment.pickup_time_slot_start && consignment.pickup_time_slot_end
            ? `${consignment.pickup_time_slot_start}-${consignment.pickup_time_slot_end}` : undefined,
        unlistedCommodityName: consignment?.unlisted_commodity_name,
        ewbNumber: consignment.ewb_number,
        ewbDate: consignment?.ewb_date ? moment(consignment.ewb_date, 'YYYY-MM-DD') : undefined,
        riskSurchargeType: consignment.risk_surcharge_type ? consignment.risk_surcharge_type : undefined,
        incoTerms: consignment.inco_terms,
    };
};

export default {
    covertDetailsToCustomerPortal,
};
